import React, { useState, useContext } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CreateButton from "./components/CreateButton"
import AbstractTable from "./components/AbstractTable";
import YearField from "../../components/YearField";
import DepartmentField from "../../components/DepartmentField";
import { useQuery } from "@tanstack/react-query";

import { Heading, Stack, Box, ButtonGroup, Skeleton } from "@chakra-ui/react";

import {
	filterByUser,
	filterBySearchText,
	sortByColumn,
} from "./utils";
import SearchField from "../../components/SearchField";

import MainContainer from "../../components/MainContainer";
import { UserContext } from "../../UserContext";
import { abstractsQueryFactory, departmentsQuery, rightsQuery, settingsQuery, yearsQuery } from "../../api/queries";

export default function AbstractsSubmitted({
	// default values for state. Can be changed
	userOnly = false,
	previousYears = false,
	title = "Submitted Abstracts"
}) {

	const [searchParams, setSearchParams] = useSearchParams()
	const { isPending: settingsLoading, data: settings } = useQuery(settingsQuery)
	const { isPending: yearsLoading, data: years } = useQuery(yearsQuery)
	const { isPending: departmentsLoading, data: departments } = useQuery(departmentsQuery)

	// convoluted year logic but it works, sorry about that :P
	const selectedYear = previousYears ? parseInt(searchParams.get('year') ?? settings?.relevant_year - 1) : settings?.relevant_year;
	const selectedDepartmentId = parseInt(searchParams.get('department') || -1);
	const searchText = searchParams.get('search') || "";
	const sortColumn = searchParams.get('sortBy') || "title";
	const sortDescending = Boolean(searchParams.get('desc')) || false;

	const user = useContext(UserContext);
	const loggedIn = !!user; // cast to bool

	const { isPending: rightsLoading, data: rights } = useQuery(rightsQuery)

	const { isPending: abstractsLoading, data: abstracts } = useQuery({
		...abstractsQueryFactory(selectedYear, selectedDepartmentId === -1 ? "all" : selectedDepartmentId),
		enabled: !!settings, // depends on settings being loaded first
	})

	const loadingBaseData = departmentsLoading
							|| yearsLoading
							|| settingsLoading
							|| rightsLoading

	const navigate = useNavigate();

	function filteredAbstracts() {
		if(!abstracts) return [];
		let filteredAbstracts = abstracts;
		if (userOnly && user) filteredAbstracts = filterByUser(abstracts, user.net_id);
		filteredAbstracts = filterBySearchText(filteredAbstracts, searchText);
		filteredAbstracts = sortByColumn(filteredAbstracts, sortColumn, sortDescending);
		return filteredAbstracts;
	}

	function updateQueryParams(newParams){
		const queryParams = new URLSearchParams(searchParams)

		for (const [key, value] of Object.entries(newParams)){
			queryParams.set(key, value)
			// special cases
			if(key === "year" && value === -1) queryParams.delete("year")
			if(key === "department" && value === -1) queryParams.delete("department")
			if(key === "desc" && value === false) queryParams.delete("desc")
		}

		setSearchParams(queryParams, {replace: true})
	}

	function triggerSortByColumn(column){
		if(sortColumn === column) updateQueryParams({desc: !sortDescending})
		else{
			updateQueryParams({
				sortBy: column,
				desc: false,
			})
		}
	}

	if(loadingBaseData) return <Skeleton />

	return (
		<MainContainer>
			<Heading as="h1" size="lg">
				{title}
			</Heading>
			<Stack spacing={3} my="1em">
				<CreateButton 
					loading={abstractsLoading}
					loggedIn={loggedIn}
					showCreate={true}
					applicationOpen={new Date(settings.application_open+"T00:00:00") <= new Date() &&
						new Date(settings.submission_deadline+"T23:59:59") >= new Date()}
					userIsAdminOrDepCoord={user?.right_id >= rights.department_coordinator.id}
				/>
				{!loggedIn &&
					<Box px={3}>Sign in to create a new abstract</Box>
				}
				<ButtonGroup size="md" spacing={4} direction="row">
					{ previousYears && (
						<YearField
							removeFirst={false}
							years={years}
							selected={selectedYear}
							onYearChange={(value) => updateQueryParams({year: value})}
						/>
					)}
					<DepartmentField
						placeholder="All Departments"
						departments={departments}
						selected={selectedDepartmentId}
						onDepartmentChange={(value) => updateQueryParams({department: value})}
					/>
				</ButtonGroup>
				<SearchField
					searchText={searchText}
					label="Search Abstracts"
					w="16em"
					placeholder="Title, student, or faculty name"
					onSearchChange={(value) => updateQueryParams({search: value})}
				/>
			</Stack>
			<AbstractTable
				loading={abstractsLoading}
				abstracts={filteredAbstracts()}
				onViewDetails={(id) => navigate(`/abstract/detail/${id}`)}
				onSort={(column) => triggerSortByColumn(column)}
			/>
		</MainContainer>
	);
}
