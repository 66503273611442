import React from 'react';
import { Grid, Box, Text, UnorderedList, ListItem, Image } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import mentoredResearch3 from "../../../assets/img/mentored-research3.jfif";
import { departmentsQuery } from "../../../api/queries";
import { useQuery } from "@tanstack/react-query"



export default function CoordinatorCard() {
    const { data: departments } = useQuery(departmentsQuery)

    function generateFacultyLink(faculty){
        if (faculty?.directory_link) {
            return faculty.directory_link;
        }
        if (faculty.full_name === "Chris Tracy"){
            return "https://researchdevelopment.byu.edu/directory/christopher-m-tracy"
        }
        // This is because he's on a different site for some reason. Once he's no longer coordinator, feel free to boot him out. 

        return `https://science.byu.edu/directory/${(faculty.full_name).toLowerCase().replace(' ', '-')}`
    }

    return (
        <Grid
            templateColumns={["1fr", "2fr 1fr", null, "1.5fr 1fr"]}
            gap={2}
            mb={6}
            py={[4, null, null, 10]}
            bgColor="grey.100"
            alignItems="center"
            justifyItems="center"
        >
            <Box px={8}>
                <Text
                    mt={2}
                    fontSize={["0.9em", null, null, "1.2em"]}
                    fontWeight="600"
                >
                    If you have questions about the conference,
                    please contact your department coordinator
                    from the list below:
                    <br />
                </Text>
                <UnorderedList mt="1em">
                    {departments.filter(d => d.coordinator).map(d => d.coordinator).map(coordinator => (
                        <ListItem key={coordinator.id}>
                            <u>
                                <a href={generateFacultyLink(coordinator)}>
                                {coordinator.full_name}
                                {" (" + (departments.find(element => element.id === coordinator.department_id)?.name ?? "No Department") + ")"}
                                <ExternalLinkIcon mx="2px" />
                                </a>
                            </u>
                        </ListItem>
                    ))}
                </UnorderedList>
            </Box>
            <Image
                maxH={["125px", null, null, "250px"]}
                src={mentoredResearch3}
                alt="Faculty working in a chemistry research lab"
                mt={[2, 0]}
            />
        </Grid>
    );
};