import MainContainer from "../../components/MainContainer";
import { Center, Stack } from "@chakra-ui/react";
import AbstractForm from "../../components/AbstractForm";

export default function AbstractCreate() {

	return (
		
		<MainContainer>
			<Center>
				<Stack maxWidth="75ch">
					<AbstractForm />
				</Stack>
			</Center>
		</MainContainer>
	);
}
