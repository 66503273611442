import React, { useContext, useEffect} from "react";

import { useNavigate } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";
import { Link, Skeleton } from "@chakra-ui/react"

import header from "../../assets/img/src-header.jpg";
import mentoredResearch1 from "../../assets/img/mentored-research.jpg";
import mentoredResearch2 from "../../assets/img/mentored-research2.jpg";

import { Box, Center, Button, Heading, Image, Text, Grid, Container }
	from "@chakra-ui/react";

import APP_PAGES from "../../page-constants";
import { simpleDateFormat, strToDate } from "../../utils";
import { departmentsQuery, rightsQuery, settingsQuery } from "../../api/queries";

import { useQuery } from "@tanstack/react-query"
import { UserContext } from "../../UserContext";

import CoordinatorCard from "./components/CoordinatorCard";
import MentorCard from "./components/MentorCard";

export default function Homepage() {
	const user = useContext(UserContext)
	const loggedIn = !!user; // convert to bool
	
    const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	
	// used to display list of faculty coordinators
	// "faculty coordinator" and "department coordinator"
	// mean the same thing, in case you are confused.
	const { isPending: departmentsPending } = useQuery(departmentsQuery)
	
	const navigate = useNavigate();
	useEffect(() => {
		if (loggedIn) {
			if (user.email === null) {
				navigate(APP_PAGES.HOMEPAGE.path);
			}
		}
	}, [user, loggedIn, navigate]);


	const loading = settingsPending || rightsPending || departmentsPending;
	if(loading) return (<>
		<Container maxW="container.lg">
		<Grid templateColumns={["1fr"]} gap={[6, null, null, 8]}>
			<Skeleton height="300px" />
			<Skeleton height="100px"/>
			<Skeleton height="100px"/>
			<Skeleton height="200px"/>
		</Grid>
		</Container>
	</>)
	
	const relevantYear = settings.relevant_year;

	function OpenMessage() {
		if (new Date(settings.application_open+"T00:00:00") <= new Date()) {
			if(new Date(settings.submission_deadline+"T23:59:59") <= new Date()){
				return (
					<>
						Abstracts submissions for {relevantYear} are currently closed.
						{new Date(settings.conference_date) > new Date() && (
							<>
								<br />
								The conference is scheduled for {simpleDateFormat(settings.conference_date)}.
							</>
						)}
					</>
				);
			}
			else {
				return (
					<>
						Abstracts submissions for {relevantYear} are currently open.
						<br />
						The submission period ends on {simpleDateFormat(settings.submission_deadline)} at 11:59 pm.
					</>
				);
			}
		} else {
			return (
				<>
					Abstracts submissions for {relevantYear} will open on {simpleDateFormat(settings.application_open)}
					{new Date(settings.conference_date) > new Date() && (
						<>
							<br />
							The conference is scheduled for {simpleDateFormat(settings.conference_date)}.
						</>
					)}
				</>
			);
		}
	}

	// convert now into the proper time zone
	const today = new Date();
	const conference_date = strToDate(settings.conference_date);

	// winners are visible one day after the conference
	function addDays(date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	  }
	const winners_publicized_date = addDays(conference_date, 1);
	const winnerOverride = user?.right_id >= rights.department_coordinator.id;
	const canViewWinners = winners_publicized_date <= today || (winnerOverride && conference_date <= today);
	const canViewSurvey = (conference_date <= today) && (addDays(conference_date, 1) >= today);

	return (
		<Container maxW="container.lg">
			<Grid templateColumns={["1fr"]} gap={[6, null, null, 8]}>
				<Box bg="grey.100">
					<Image
						objectFit="cover"
						src={header}
						alt="Student Research Conference"
					/>
				</Box>
				{conference_date <= (today - (- 2*24*60*60*1000)) && (
					// today + 2 days
					// 2 days * 24 hours * ... * 1000 milliseconds
				<Grid
					templateColumns={["1fr"]}
					gap={2}
					py={[4, null, null, 10]}
					bgColor="grey.100"
					alignItems="center"
					justifyItems="center"
				>
					<Box px={8}>
						<Heading
							as="h3"
							textAlign="center"
							fontSize={["1.1em", null, null, "1.6em"]}
						>
							Conference Information
							<Center mt="1em">
								<Link m={1} as={RouterLink} to={`${APP_PAGES.SESSION_FIND.path}`}>
									<Button size="md" colorScheme="green">
										Find Your Session
									</Button>
								</Link>
						{ canViewWinners && (
								<Link m={1} as={RouterLink} to={`${APP_PAGES.WINNERS.redirect}/${settings.relevant_year}`}>
									<Button size="md" colorScheme="blue">
										View Winners
									</Button>
								</Link>
						)}
							</Center>	
						</Heading>
					</Box>
				</Grid>
				)}

				{canViewSurvey && <Grid
					templateColumns={["1fr"]}
					gap={2}
					py={[4, null, null, 10]}
					bgColor="grey.100"
					alignItems="center"
					justifyItems="center"
				>
					<Box px={8}>
						<Heading
							as="h3"
							textAlign="center"
							fontSize={["1.1em", null, null, "1.6em"]}
						>
							Conference Survey
						</Heading>
						<Image src="/DinoQR.png" alt="Survey QR Code" mt={4} mb={4}/>
						<Center mt="1em">
							<a href="https://docs.google.com/forms/d/e/1FAIpQLSfF1Fiaha5KKZanbolvK4Fm4jQZo7iDdAD1ZPGh-_BgZzJChg/viewform">
								<Button size="md" colorScheme="blue">
									Take Survey
								</Button>
							</a>
							
						</Center>
					</Box>
				</Grid>}

				<Grid
					templateColumns={["1fr"]}
					gap={2}
					py={[4, null, null, 10]}
					bgColor="grey.100"
					alignItems="center"
					justifyItems="center"
				>
					<Box px={8}>
						<Heading
							as="h3"
							textAlign="center"
							fontSize={["1.1em", null, null, "1.6em"]}
						>
							Submit Abstract
						</Heading>
						<Text
							textAlign="center"
							mt={2}
							fontSize={["0.9em", null, null, "1.2em"]}
							fontWeight="600"
						>
							<OpenMessage />
						</Text>
						
						{ new Date(settings.application_open+"T00:00:00") <= new Date() &&
						  new Date(settings.submission_deadline+"T23:59:59") >= new Date() &&
							<Center mt="1em">
								<Link as={RouterLink} to={APP_PAGES.ABSTRACTS_SUBMITTED.path}>
									<Button size="md" colorScheme="green">
										Submit Abstract
									</Button>
								</Link>
							</Center>
						}
					</Box>
				</Grid>
				{/* <MentorCard /> */}
				<Grid
					templateColumns={["1fr", "2fr 1fr", null, "1.5fr 1fr"]}
					gap={2}
					py={[4, null, null, 10]}
					bgColor="grey.100"
					alignItems="center"
					justifyItems="center"
				>
					<Box px={8}>
						<Heading
							as="h2"
							textAlign="center"
							fontSize={["1.1em", null, null, "1.6em"]}
						>
							What is it?
						</Heading>
						<Text
							mt={2}
							fontSize={["0.9em", null, null, "1.2em"]}
							fontWeight="600"
						>
							The Student Research Conference (SRC)
							is <strong>{simpleDateFormat(settings.conference_date, true)}. </strong>
							It is an annual event sponsored by the College of
							Computational, Mathematical and Physical Sciences
							(CMS) that gives both graduate and undergraduate
							students the opportunity to present their research
							in a professional conference setting.
							<br />
							<br />
							Anyone is welcome to attend the conference and view presentations. <Link as={RouterLink} to={APP_PAGES.SCHEDULE.default_path} target="_blank" color="blue.500">
								View the schedule.</Link>
						</Text>
					</Box>
					<Image
						maxH={["125px", null, null, "250px"]}
						src={mentoredResearch1}
						alt="Student being guided by a professor while looking a computer screen"
						mt={[2, 0]}
					/>
				</Grid>
				<Grid
					templateColumns={["1fr", "2fr 1fr", null, "1.5fr 1fr"]}
					gap={2}
					py={[4, null, null, 10]}
					bgColor="grey.100"
					alignItems="center"
					justifyItems="center"
				>
					<Box px={8}>
						<Heading
							as="h2"
							textAlign="center"
							fontSize={["1.1em", null, null, "1.6em"]}
						>
							Why participate?
						</Heading>
						<Text
							mt={2}
							fontSize={["0.9em", null, null, "1.2em"]}
							fontWeight="600"
						>
							Students who have participated in past conferences cite the SRC as
							a valuable experience that augmented their BYU education and
							helped to better prepare them for graduate school and/or industry.
							<br />
							<br />
							In general over 600 students present yearly. In addition, a pizza
							luncheon and door prize drawings are provided for all attendees of
							the conference.
						</Text>
					</Box>
					<Image
						maxH={["125px", null, null, "250px"]}
						src={mentoredResearch2}
						alt="Student working in a chemistry research lab"
						mt={[2, 0]}
					/>
				</Grid>
				<CoordinatorCard />
			</Grid>
		</Container>
	);
}
